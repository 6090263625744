<template>
	<!--검색 조건 카드 시작-->
	<searchCard>
		<h4 class="mb-3 searchCardTitle" v-text="seq + '. ' + pData.title + '검색'" />
		<div class="flex">
			<v-select class="w-1/3" v-model="opt.searchKeyName" :clearable="false" :options="pData.searchKeyOpts" />
			<b-form-input
				class="w-1/2 ml-4"
				v-model.trim="opt.searchKeyword"
				placeholder="검색어"
				@keyup.enter="search"
			/>
			<b-button class="w-1/6 ml-4" @click="search">검색</b-button>
		</div>
		<h6 class="mt-2">※ 미입력 시 전체 {{ pData.title }} 노출</h6>
	</searchCard>
</template>

<script>
export default {
	props: ['seq', 'pData'],
	data() {
		const defOpt = {
			searchKeyName: this.pData.searchKeyOpts[0],
			searchKeyword: '',
		}
		return {
			opt: { ...defOpt },
			defOpt,
			lastOpt: {},
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//뷰/테마 설정인지 개발자 설정인진 모르겠는데 기본값 설정을 해도 isTrusted 객체로 온다?
			if (typeof operation != 'string') operation = 'LIKE'
			const options = this.opt,
				searchKeyName = getDropdownValue(options.searchKeyName),
				searchVal = this.opt.searchKeyword

			let data = { ...this.pData.defaultSearchData },
				error = []
			//선택 조건에 없는 필수값들 입력

			if (searchKeyName && searchVal)
				data.searchKey = [
					{
						fieldName: searchKeyName,
						value: searchVal,
						andOr: 'and',
						operation,
					},
				]
			if (error.length) alert.w(error)
			else {
				this.lastOpt = data
				this.pData.selectedItem = ''
				//this.pData.childDeleteSelectedItem()
				//검색할 때는 기존 선택값 초기화
				return this.changePage(1)
			}
		},
		changePage(page = 1) {
			let data = this.lastOpt
			data.paging.pageNo = page - 1
			return postApi(this.pData.apiLink, data).then(res => {
				this.pData.res = this.pData.formatting(res)
			})
		},
	},
}
</script>
