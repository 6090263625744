<template>
	<div id="couponAddWrap">
		<b-card class="mb-4 pt-8">
			<div class="flex">
				<p class="w-25 mb-8">쿠폰명</p>
				<input class="form-control -mt-1.5 mb-1.5" v-model.trim="input.couponName" type="text" />
			</div>
			<div class="flex">
				<div
					v-for="v in [
						{ title: '할인조건', key: 'minOrderPrice', after: '원 이상 구매 시' },
						{ title: '할인금액', key: 'discountPrice', after: '원 할인' },
						{ title: '발급 후 유효기간', key: 'validPeriod', after: '일 동안 사용 가능' },
					]"
					class="w-1/3 flex"
				>
					<!--					<p class="w-40">{{ v.title }}</p>-->
					<input
						class="w-44 form-control -mt-1.5 mb-1.5"
						v-model.trim="input[v.key]"
						type="text"
						@focusin="inputFocusIn(input, v.key)"
						@focusout="inputFocusOut(input, v.key)"
					/>
					<p>{{ v.after }}</p>
				</div>
			</div>
			<div class="flex mt-3">
				<p class="mt-1.5">쿠폰 유효 기간</p>
				<datePicker placeholder="from" model="input.startDt" />
				<span class="p-2 lh-6">~</span>
				<datePicker placeholder="to" model="input.endDt" />

				<b-form-checkbox
					v-for="v in [
						{ title: '중복 사용 가능 여부', key: 'isDuplicate' },
						{ title: '내돈내산 전용 여부', key: 'onlySelfDeal' },
						{ title: '특정 상품 쿠폰 여부', key: 'couponType' },
					]"
					class="ml-6 mt-1.5"
					v-model="input[v.key]"
					>{{ v.title }}
				</b-form-checkbox>
				<button
					:class="isEdit ? 'btn-info' : 'btn-primary'"
					class="w-40 absolute bottom-0 right-0 btn"
					@click.stop.prevent="save"
					type="button"
				>
					{{ isEdit ? '수정하기' : '저장하기' }}
				</button>
			</div>
		</b-card>

		<div class="flex">
			<div class="w-1/2 mr-2">
				<manualDealSearchCard :pData="productGroup" cName="productGroupSearchCard" seq="1" />
				<b-card>
					<tb
						@changePage="productGroup.changePage"
						@click="productGroup.selectItem"
						:inf="productGroup.inf"
						:res="productGroup.res"
						cName="productGroupTable"
						limit="20"
						:trClass="
							v =>
								input.productGroupList.find(obj => obj.productGroupIdx == v.productGroupIdx)
									? 'bg-blue'
									: ''
						"
						selectable
					/>
				</b-card>
			</div>
			<div class="w-1/2 ml-2">
				<b-card>
					<div class="flex-between">
						<div class="flex">
							<h4>일괄</h4>
							<b-button
								v-for="kind in ['left', 'right']"
								class="border-gray ml-4 mb-2 py-1.5"
								size="sm"
								variant="no"
								@click="handleProductGroupAll(kind)"
							>
								<b-icon :icon="`chevron-double-${kind}`" />
							</b-button>

							<vSelect
								class="w-40 ml-8"
								v-model="localOrder.key"
								@input="orderProductGroup"
								:clearable="false"
								:options="localOrder.opts"
							/>
							<b-form-radio
								v-for="v in [
									{ title: '오름차순', val: 'asc' },
									{ title: '내림차순', val: 'desc' },
								]"
								class="ml-4 mt-1.5"
								v-model="localOrder.desc"
								name="orderProductGroupDesc"
								:value="v.val"
								@input="orderProductGroup"
							>
								{{ v.title }}
							</b-form-radio>
						</div>
						<h4>선택된 상품 그룹</h4>
					</div>
					<div class="selectListWrap">
						<b-form-input
							class="float-right"
							v-model.trim="input.searchText"
							name="selectListSearchText"
							placeholder="상품 그룹 검색"
							@keyup="productGroupFilter"
						/>

						<div id="selectableListWrap">
							<p
								v-for="v in input.productGroupList"
								v-show="!input.searchText || (v.isShow && !v.isSelected)"
								:class="v == input.selectedList ? 'selected' : ''"
								class="selectListItem p-2"
							>
								{{ v.val }}
								<b-button
									class="ml-2 float-right -top-1"
									@click.stop="handleProductGroup(v, -1)"
									size="sm"
									variant="no"
								>
									<b-icon font-scale="1" icon="trash" />
								</b-button>
							</p>
						</div>
					</div>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
//미묘하게 공통으로 뽑아낼 수 있을 거 같으면서도 안 되는 객체들이 많다... 맘에 안 들게
//수동딜 등록의 상품 그룹 검색과 핫티스트 심사 팝업의 리스트 결합
import manualDealSearchCard from 'pages/manualDealAddSearchCard'

let ths
export default {
	components: { manualDealSearchCard },
	props: { couponSeq: { default: '' } },
	data() {
		const now = get_time('d')
		return {
			isEdit: this.couponSeq != '',

			input: {
				couponName: '',
				discountPrice: 3000,
				validPeriod: 1,
				minOrderPrice: 30000,
				startDt: now,
				endDt: now,
				isDuplicate: false,
				onlySelfDeal: false,
				couponType: true,

				searchText: '',
				productGroupList: [],
			},
			originProductGroupIdxList: [],
			productGroup: {
				title: '상품그룹',
				searchKeyOpts: [
					{ label: '상품그룹명', value: 'productGroupName' },
					{ label: 'idx', value: 'productGroupIdx' },
					{ label: '브랜드명', value: 'brandName' },
					{ label: '노출등급', value: 'productGroupClass' },
				],
				apiLink: '/productGroupSelect/productGroupList',
				defaultSearchData: {
					paging: getPaging(0, 20),
					orderBy: getOrder('productGroupIdx'),
				},
				res: [],
				formatting(res) {
					//서버에서 값 조회 후 프론트에서 보여주는 포맷에 맞게 정리
					res.list = res.list.map(v => {
						v.enrollDate = v.enrollDate.substr(0, 10)
						//v.productType = ths.getProductType(v.productType)
						v.child = false
						if (v.productGroupClass == 'z') v.trClass = 'text-gray-light'
						v.essentialStatus = v.isEssential ? 'O' : 'X'
						return v
					})
					return res
				},
				changePage(page = 1) {
					ths.productGroupSearchCard.changePage(page)
				},

				inf: [
					{ title: 'idx', key: 'productGroupIdx', size: 4 },
					{ title: '브랜드명', key: 'brandName', size: 10 },
					{ title: '상품그룹명', key: 'productGroupName', size: 20, notCenter: 1 },
					{ title: '노출 등급', key: 'productGroupClass', size: 4 },
					{ title: '등록일', key: 'enrollDate', size: 12 },
				],
				selectedItem: '',
				selectItem(v, i) {
					//상품그룹 선택
					ths.productGroup.selectedItem = v
					ths.handleProductGroup(v)
					//선택된 상품 그룹 토글
				},
			},

			localOrder: {
				key: { label: '상품 그룹 idx', value: 'productGroupIdx' },
				desc: 'asc',
				opts: [
					{ label: '상품 그룹 idx', value: 'productGroupIdx' },
					{ label: '상품그룹명', value: 'productGroupName' },
					{ label: '브랜드명', value: 'brandName' },
				],
			},
		}
	},
	methods: {
		editPageInit() {
			layout.pageTitle = '쿠폰 수정'
			//쿠폰 수정으로 들어왔을 때 초기화 루틴
			const couponSeq = this.$route.params.couponSeq
			if (couponSeq) {
				//넘어온 couponSeq를 이용해서 기존 쿠폰 정보 조회
				postApi('api/backoffice/couponSelect/couponList', {
					searchKey: [{ fieldName: 'couponSeq', andOr: 'and', operation: 'EQUAL', value: couponSeq }],
					paging: getPaging(),
				}).then(res => {
					res = res.list
					if (!res.length) {
						alert.w('조회된 결과가 없습니다')
						this.$router.go(-1)
						return
					}
					res = res[0]
					;['discountPrice', 'minOrderPrice', 'validPeriod'].map(k => (res[k] = comma(res[k])))
					res.couponType = res.couponType == 1

					Object.keys(res).map(k => {
						this.input[k] = res[k]
					})
				})

				postApi('api/backoffice/productSelect/selectCouponProductList', { couponSeq }).then(res => {
					this.originProductGroupIdxList = res.list.map(v => v.productGroupIdx)
					this.$set(
						this.input,
						'productGroupList',
						res.list.map(v => {
							v.brandName = v.brand
							v.val = `${v.productGroupIdx} / ${v.brandName} ${v.productGroupName}`
							return v
						})
					)
					this.orderProductGroup()
				})
			} else {
				alert.w('쿠폰 seq가 잘못되었습니다')
				this.$router.go(-1)
			}
		},

		productGroupFilter() {
			const searchText = this.input.searchText.split(' ')
			this.input.productGroupList.map((v, i) => {
				let isShow = true
				searchText.map(s => {
					if (s && v.val.indexOf(s) == -1) isShow = false
				})
				v.isShow = isShow
				this.$set(this.input.productGroupList, i, v)
			})
		},

		inputFocusIn(v, key = 'adLimitAmt') {
			const target = v ? v : this
			this.$set(target, key, target[key].toString().replace(/,/g, ''))
		},
		inputFocusOut(v, key = 'adLimitAmt') {
			const target = v ? v : this
			this.$set(target, key, comma(target[key]))
		},

		save() {
			const inp = this.input,
				data = {
					couponName: inp.couponName,
					discountPrice: num(inp.discountPrice),
					validPeriod: num(inp.validPeriod),
					minOrderPrice: num(inp.minOrderPrice),
					startDt: inp.startDt ? inp.startDt.get_time('d') : '',
					endDt: inp.endDt ? inp.endDt.get_time('d') : '',
					isDuplicate: inp.isDuplicate,
					onlySelfDeal: inp.onlySelfDeal,
				},
				e = []
			;[
				{ key: 'couponName', title: '쿠폰명' },
				{ key: 'startDt', title: '쿠폰 유효기간 시작일' },
				{ key: 'endDt', title: '쿠폰 유효기간 종료일' },
			].map(v => {
				if (!data[v.key]) e.push(v.title + '을 확인 해주세요')
			})
			if (data.discountPrice < 1) e.push('할인 금액을 확인 해주세요')
			if (e.length) return alert.w(e)
			//유효성 검사 끝

			if (inp.couponType) {
				//나중에 true/false 말고 다른 걸로 수정 예정
				data.couponGroupType = '3'
				data.couponType = 1
			} else {
				data.couponGroupType = '1'
				data.couponType = 0
			}

			if (this.isEdit) {
				//수정의 경우 몇 가지 추가
				data.couponSeq = this.couponSeq * 1
				data.isDelete = false
			}

			const api = this.isEdit
				? putApi('api/backoffice/couponHandle/updateCoupon', data)
				: postApi('api/backoffice/couponHandle/insertCoupon', data)
			api.then(res => {
				//쿠폰 기본 정보 수정까진 기본적으로 작동함
				//이제 상품 그룹 정보 등록 해야 함
				//상품 그룹 정보 등록에는 상품 그룹 추가와 삭제가 있고, 빈 배열이면 안 보내야 됨(서버에서 오류 뱉음)
				let append = inp.productGroupList.map(v => v.productGroupIdx),
					remove = []
				if (this.isEdit) {
					if (!this.originProductGroupIdxList && !append.length) return success()
					//원래도 상품 그룹 없었고 이번에도 없으면 더 진행할 필요 없음

					const originProductGroup = copyVar(this.originProductGroupIdxList)
					append = append.filter(v => {
						const idx = originProductGroup.indexOf(v)
						if (idx == -1) return true
						//원래 없던 항목 추가
						originProductGroup.splice(idx, 1)
						//있던 항목이면 origin에서 항목 제거
						return false
					})
					remove = originProductGroup
					//위 과정에서 남은 origin은 제거된 항목이다
				} else this.couponSeq = res

				const tpPromise = makePromise()
				const funcAppend = append.length
					? postApi('api/backoffice/productHandle/insertCouponProduct', {
							couponSeq: this.couponSeq,
							productGroupIdxList: append,
					  })
					: tpPromise

				funcAppend.then(() => {
					const funcRemove = remove.length
						? putApi('api/backoffice/productHandle/deleteCouponProduct', {
								couponSeq: this.couponSeq,
								productGroupIdxList: remove,
						  })
						: tpPromise

					funcRemove.then(() => {
						alert.s('쿠폰 ' + (this.isEdit ? '수정' : '생성') + '에 성공했습니다')
						this.$router.go(-1)
					})
				})
			})
		},
		handleProductGroupAll(kind) {
			//상품 그룹 일괄 처리 left, right
			if (kind == 'left') return this.$set(this.input, 'productGroupList', [])
			const list = this.productGroup.res?.list
			if (Array.isArray(list))
				list.map(v => {
					this.handleProductGroup(v, 1)
				})
			this.orderProductGroup()
		},
		handleProductGroup(productGroup, kind = 0) {
			//productGroup: { brandName, productGroupIdx, productGroupName }
			//kind: -1: 선택 상품 그룹 제거, 0: 선택 상품 그룹 토글, 1: 선택 상품 그룹 추가
			const selectedIdx = this.input.productGroupList.findIndex(
				obj => obj.productGroupIdx == productGroup.productGroupIdx
			)
			if (selectedIdx != -1) {
				//이미 선택 되어 있다
				if (kind != 1)
					//상품 추가로 들어온 경우가 아니면 기존 선택 상품 제거
					return this.input.productGroupList.splice(selectedIdx, 1)
			} else if (kind != -1) {
				//상품 제거로 들어온 경우가 아니면 상품 추가
				const item = { ...productGroup }
				item.val = `${item.productGroupIdx} / ${item.brandName} ${item.productGroupName}`
				return this.input.productGroupList.push(item)
			}
		},

		orderProductGroup() {
			const key = getDropdownValue(this.localOrder.key)
			if (!key) return

			sort_arr(this.input.productGroupList, key, this.localOrder.desc == 'asc')
		},
	},
	mounted() {
		ths = this
		if (this.isEdit) this.editPageInit()
		//쿠폰 정보 수정으로 들어왔을 때 처리
	},
	computed: {},
	watch: {
		$route: {
			//같은 컴포넌트면서 주소가 바뀐다면 변경되는 내용 바꿔줘야 한다
			handler() {
				this.isEdit = this.couponSeq != ''
				if (this.isEdit) this.editPageInit()
			},
		},
	},
}
</script>
<style>
.selectListWrap {
	width: 100%;
	height: 54.5rem;
	border: 1px solid var(--gray);
	border-radius: 5px;
}

.selectListWrap #selectableListWrap {
	width: 100%;
	height: calc(100% - 2.3rem);
	overflow-y: scroll;
}

.selectListWrap .selectListItem {
	cursor: pointer;
	user-select: none;
}

.selectListWrap .selectListItem.selected,
.selectListWrap .selectListItem.moveTarget {
	color: var(--primary) !important;
	background-color: rgba(40, 199, 111, 0.12);
	border: rgba(40, 199, 111, 0.1) 1px solid;
}

#couponAddWrap .selectedRow {
	background-color: transparent !important;
}

#couponAddWrap .selectedRow p {
	color: #636363 !important;
}

#couponAddWrap .bg-blue {
	background-color: var(--info) !important;
	color: white !important;
}

#couponAddWrap .bg-blue p {
	color: white !important;
}
</style>
